import React from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  flex: 1 1 40%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  padding: 30px 60px;
  font-size: ${props => props.theme.fontSizes[1]}px;

  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    padding: 0 15px 30px;
  }
`


const HeroCenterStandard = props => {
  return <Container>{props.children}</Container>
}

export default HeroCenterStandard
