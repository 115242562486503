/** @jsx jsx */
import { jsx } from 'theme-ui'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Container from '../components/Container'
import ContainerHero from '../components/ContainerHero'
import ContainerContent from '../components/ContainerContent'
import PageTitle from '../components/PageTitle'
import PageBody from '../components/PageBody'
import SEO from '../components/SEO'
import RichTextRenderer from '../components/RichTextRenderer'
import HeroLeftStandard from '../components/HeroLeftStandard'
import HeroCenterStandard from '../components/HeroCenterStandard'

const ReferenzenBody = styled.div`
  font-size: ${props => props.theme.fontSizes[1]}px;
  @media (min-width: ${props => props.theme.responsive.small}) {
    column-count: 2;
    font-size: ${props => props.theme.fontSizes[3]}px;
    column-gap: 30px;
    white-space: pre;
  }
  @media (min-width: ${props => props.theme.responsive.medium}) {
    column-count: 2;
    font-size: ${props => props.theme.fontSizes[4]}px;
    column-gap: 60px;
    white-space: pre;
  }

  p{
    line-height: 1.2em;
    margin-bottom: 12px;
  }
`

const PageTemplate = ({ data }) => {
  const {
    title,
    metaDescription,
    slug,
    kurzbeschreibung,
  } = data.contentfulPage
  const body = data.contentfulPage.body.json
  return (
    <Layout>
      <SEO
        title={title}
        description={
          metaDescription ? metaDescription.internal.content : 'by EXTENDIT'
        }
      />
      <Container>
        <ContainerHero>
          <HeroLeftStandard>
            <PageTitle>{title}</PageTitle>
          </HeroLeftStandard>
          {kurzbeschreibung && (
            <HeroCenterStandard>
              {kurzbeschreibung.internal.content}
            </HeroCenterStandard>
          )}
        </ContainerHero>
        <ContainerContent>
          {slug === 'referenzen' ? (
            <PageBody>
              <ReferenzenBody>
                <RichTextRenderer richTextJson={body} />
              </ReferenzenBody>
            </PageBody>
          ) : (
            <PageBody>
              <RichTextRenderer richTextJson={body} />
            </PageBody>
          )}
        </ContainerContent>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      slug
      metaDescription {
        internal {
          content
        }
      }
      kurzbeschreibung {
        internal {
          content
        }
      }
      body {
        json
      }
    }
  }
`

export default PageTemplate
