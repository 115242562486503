import React from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  width: 100%;
  @media (min-width: ${props => props.theme.responsive.medium}) {
    width: 60%;
    padding: 30px 60px;
  }
  padding: 30px 15px;
  font-size: ${props => props.theme.fontSizes[5]}px;
`

const HeroLeftStandard = props => {
  return <Container>{props.children}</Container>
}

export default HeroLeftStandard
